<template>
  <div class="overdue">
    <el-container class="jietiao">
      <el-main class="main-content">
        <el-container>
          <el-header class="flex-box flex-start text-bold page-title">
            逾期借条
          </el-header>
          <el-main>
            <div class="search-box">
              <el-form inline :model="formInline" label-width="120">
                <el-form-item>
                  <el-input
                    clearable
                    style="width: 220px"
                    placeholder="请输入关键字"
                    v-model="formInline.searchName"
                    @clear="handleReset"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button size="medium" type="primary" @click="handleSearch"
                    >查询</el-button
                  >
                  <el-button size="medium" @click="handleReset">重置</el-button>
                </el-form-item>
              </el-form>
            </div>

            <el-table stripe :data="listData" height="600">
              <el-table-column prop="lendName" label="出借人"></el-table-column>

              <el-table-column
                prop="borrowName"
                label="借款人"
              ></el-table-column>

              <el-table-column
                prop="borrowAmount"
                label="借款金额"
              ></el-table-column>

              <el-table-column prop="delayFlag" label="是否延期">
                <template slot-scope="scope">
                  {{ Boolean(scope.row.delayFlag) ? "是" : "否" }}
                </template>
              </el-table-column>

              <el-table-column prop="status" label="状态" width="120">
                <template slot-scope="scope">
                  {{ getItemByKey(scope.row.status, statusList).name }}
                </template>
              </el-table-column>

              <el-table-column prop="borrowTime" label="借款时间" width="160">
                <template slot-scope="scope">
                  {{ formatDate(scope.row.borrowTime) }}
                </template>
              </el-table-column>

              <el-table-column prop="refundTime" label="还款日期" width="160">
                <template slot-scope="scope">
                  {{ formatDate(scope.row.refundTime) }}
                </template>
              </el-table-column>

              <el-table-column prop="delay" label="延期还款日期" width="160">
                <template slot-scope="scope">
                  {{ formatDate(scope.row.delay) }}
                </template>
              </el-table-column>

              <el-table-column prop="remark" label="备注" width="250">
                <template slot-scope="scope">
                  <span
                    :class="{
                      'color-error':
                        (scope.row.paramMap || {}).finalStatus === '1',
                    }"
                  >
                    {{ (scope.row.paramMap || {}).remark }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="操作" min-width="240" fixed="right" center>
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="handleDetails(scope.row)"
                  >
                    查看详情
                  </el-button>
                  <el-button
                    size="mini"
                    @click="handleView(scope.row)"
                  >
                    查看借款协议
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-main>
        </el-container>
      </el-main>
    </el-container>

    <el-dialog
      center
      width="600px"
      title="借款信息"
      :close-on-click-modal="false"
      :visible.sync="visible"
    >
      <contract-info :id="details.id" v-if="visible" @submit="handleSubmit" />
    </el-dialog>

    <el-dialog
      center
      top="50px"
      width="800px"
      title="借款合同信息"
      :close-on-click-modal="false"
      :visible.sync="visibleContract"
    >
      <contract :id="details.id" v-if="visibleContract" />
    </el-dialog>
  </div>
</template>

<script>
import { listByOverdue } from "@/api/jietiao";
import { formatDate, getItemByKey } from "@/common/utils";
import { statusList } from "@/common/const";
import Contract from "../jietiao/contract.vue";
import ContractInfo from "../jietiao/contract-info.vue";

export default {
  name: "Overdue",
  components: {
    Contract,
    ContractInfo,
  },
  data() {
    return {
      formInline: {
        searchName: "",
      },
      statusList,
      listData: [],
      details: {},
      visible: false,
      visibleContract: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    getItemByKey,
    formatDate(date) {
      return formatDate(date, "y-m-d");
    },
    handleSearch() {
      this.loadData();
    },
    handleReset() {
      this.formInline = {
        searchName: "",
      };
      this.handleSearch();
    },
    loadData() {
      this.listData = [];
      listByOverdue(this.formInline.searchName).then((res) => {
        this.listData = res.data.chujies;
      });
    },
    handleDetails(data) {
      this.details = data;
      this.visible = true;
    },
    handleView(data){
      this.details = data;
      this.visibleContract = true;
    },
    handleSubmit() {
      this.loadData();
      this.visible = false;
    },
  },
};
</script>

<style lang="less">
.overdue {
}
</style>
